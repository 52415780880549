.scrollbar::-webkit-scrollbar {
    width: 10px;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #6b7dc2;
    border-radius: 100px;
}
