@import 'components/global.scss';

.pannel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: black;
    background: rgba(0, 0, 0, 0.7);

    .pannel-content {
        position: absolute;
        right: 0;
        width: 70%;
        top: 0;
        bottom: 0;
        opacity: 0;
        text-align: left;
        transform: translateX(100%);
        transition: all 0.3s;
        background-color: $c-bg;
        border-radius: $radius 0 0 $radius;

        @media (max-width: 1400px) {
            width: 80%;
        }
        @media (max-width: 1200px) {
            width: 90%;
        }
    }
    .close-btn {
        position: absolute;
        top: 0;
        left: 0;
        padding: 1rem;
        font-size: 1.5rem;
        color: $c-text;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
}

.pannel-appear {
    opacity: 0;
    pointer-events: none;
}

.pannel-appear-done {
    opacity: 1;
    pointer-events: auto;

    .pannel-content {
        opacity: 1;
        top: 0;
        transform: translateX(0);
    }
}

