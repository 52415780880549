@import 'components/global.scss';

.sidebar {
    
    .btn-toggle_expand {
        position: absolute;
        right: -10px;
        top: 32px;
        z-index: 10;
        background-color: white;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        box-shadow: $shadow;
        & svg {
            color: $c-main-dark;
        }
    }

    @media (min-width: $mobile) {
        position: relative;
        width: 80px;
        height: 100%;
        z-index: 101;
        padding: 10px 10px 10px 10px;
        background: $c-main-dark-gradient;
        border-radius: $radius;
        box-shadow: $shadow;
        transition: all 0.2s ease-in-out;

        &.expanded {
            width: 220px;

            a span.label,
            div span.label {
                opacity: 1;
                transition: all 0.2s ease-in-out;
            }
        }

        > a {
            // logo

            position: absolute;
            top: $scale1;
            width: $scale5;
            height: $scale5;
            > div > img {
                width: 45px;
                height: 45px;
                object-fit: cover;
                object-position: center;
                border-radius: 50%;
                vertical-align: middle;
            }
        }

        .nav-links {
            padding: 0;
            margin-top: $scale7;

            a,
            div {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;
                color: white;
                padding: 0;
                cursor: pointer;
                width: 100% !important;
                height: $scale5;
                padding: $scale $scale-3;
                font-size: 1em;
                line-height: 1;

                .icon {
                    position: absolute;
                    top: 50%;
                    left: 20px;
                    transform: translateY(-50%);
                }

                .label {
                    opacity: 0;
                    pointer-events: none;
                    margin-left: $scale4;
                }

                &.active {
                    color: white;
                    border-radius: $radius;
                    background-color: $c-blue;
                    background: lighten($c-main-dark, 15%);
                }
            }
        }
    }
}

.popup {
    @media (min-width: $mobile) {
        .btn-togglenav {
            display: none !important;
        }
    }

    @media (max-width: $mobile) {
        position: fixed;
        top: $scale1;
        left: $scale1;
        width: $scale2;
        height: $scale2;
        z-index: 15;
        background-color: transparent;

        > a {
            display: none;
        }

        .btn-toggle_expand {
            display: none;
        }

        .btn-togglenav {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
        }

        &.open {
            .nav-links {
                display: block !important;
            }
        }

        section.nav-links {
            position: fixed;
            display: none !important;
            padding: $scale;
            z-index: 9;
            top: $scale-2;
            left: $scale-3;
            right: $scale-3;
            padding-top: $scale5;
            background-color: white;
            border-radius: $radius;
            box-shadow: 0 0 5em rgba(0, 0, 0, 0.25);
            transition: all 0.2s ease-in-out;

            a,
            div {
                position: relative;
                display: inline-block;
                float: none;
                color: $c-text;
                padding: 0;
                width: 100% !important;
                line-height: 1;
                margin-bottom: $scale;
                text-decoration: none;

                .ico {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                svg {
                    display: inline-block;
                }

                .label {
                    margin-left: $scale-2;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.active {
                    color: $c-text;
                    background: none;
                }
            }
        }
    }
}
